<template>
  <div class="CompletedRounds">
    <!-- 未连接钱包 begin -->
    <div class="no-data" v-if="connectedStatus == 0">
      <div class="no-data-desc">{{ $t("menu.connectYourWallet") }}</div>
      <div class="no-data-desc">{{ $t("menu.starsThisRound") }}</div>
      <button class="no-data-btn" @click="openDialog(connectedStatus, 1)">
        {{ $t("menu.connectWallet") }}
      </button>
    </div>
    <!-- 未连接钱包 end -->
    <!-- 没有数据 begin -->
    <div class="no-data" v-if="connectedStatus == 1 && isRoundsData == false">
      <div class="no-data-desc">{{ $t("menu.noExchanged") }}</div>
      <router-link :to="{ name: 'ExchangeLuckyStars', path: '/exchangeLuckyStars' }" class="no-data-btn">{{
          $t("menu.toExchange")
      }}</router-link>
    </div>
    <!-- 没有数据 end -->
    <!-- 有申购数据 begin -->
    <div class="" v-if="connectedStatus == 1 && isRoundsData">
      <!-- 中奖信息 begin -->
      <div class="box-draw-prize">
        <div class="prize-head">
          <div class="head-one com-all-head">
            <div class="left">
              <i>{{ $t("menu.round") }}</i><span>{{ lotteryId }}</span>
            </div>
            <div class="prev-img-p">
              <span @click="getPgage(0)"><img src="../../../../../../public/images/lukyStar/icon-index.png" alt="" /></span>
              <span @click="getPgage(1)"><img src="../../../../../../public/images/lukyStar/icon-left.png" alt="" /></span>
              <span @click="getPgage(2)"><img src="../../../../../../public/images/lukyStar/icon-right.png" alt="" /></span>
              <span @click="getPgage(3)"><img src="../../../../../../public/images/lukyStar/icon-wei.png" alt="" /></span>
            </div>
          </div>
          <div class="head-two">
            <div class="left color-grey">
              {{ $t("menu.prizeBeenDrawn") }} {{formatTimeToType1(viewLottery.endTime)}}
            </div>
          </div>
        </div>
        <!-- 中奖号码 begin -->
        <div class="prize-box">
          <div class="title">
            {{ $t("menu.winningNumbers")
            }}<span class="new-flag"><img src="../../../../../../public/images/lukyStar/new.png" alt="" /></span>
          </div>
          <div class="num all-com-winning openPrizeNumber">
            <span v-for="(item, index) in finalNumberList" :key="index">
              <img v-if="item == '0'" src="../../../../../../public/images/lukyStar/num0.png" alt="" />
              <img v-if="item == '1'" src="../../../../../../public/images/lukyStar/num1.png" alt="" />
              <img v-if="item == '2'" src="../../../../../../public/images/lukyStar/num2.png" alt="" />
              <img v-if="item == '3'" src="../../../../../../public/images/lukyStar/num3.png" alt="" />
              <img v-if="item == '4'" src="../../../../../../public/images/lukyStar/num4.png" alt="" />
              <img v-if="item == '5'" src="../../../../../../public/images/lukyStar/num5.png" alt="" />
              <img v-if="item == '6'" src="../../../../../../public/images/lukyStar/num6.png" alt="" />
              <img v-if="item == '7'" src="../../../../../../public/images/lukyStar/num7.png" alt="" />
              <img v-if="item == '8'" src="../../../../../../public/images/lukyStar/num8.png" alt="" />
              <img v-if="item == '9'" src="../../../../../../public/images/lukyStar/num9.png" alt="" />
            </span>
          </div>
        </div>
        <!-- 中奖号码 end -->
      </div>
      <!-- 中奖信息 end -->
      <div class="prize-container">
        <!-- 中奖介绍内容 begin -->
        <div class="p-name-history">
          <div class="type-choose" @click="chooseCurrency">
            <span v-if="currentCurrency == 0">STAR</span>
            <span v-if="currentCurrency == 1">ZEED</span>
            <span v-if="currentCurrency == 2">YEED</span>
            <img src="../../../../../../public/images/lukyStar/icon-down.png" alt="" />
          </div>
          <div class="prev-num-p">
            <i>{{ currentInfoPage }}</i><span>/{{ infoTotalPage }}</span>
          </div>
        </div>
        <div class="p-tip-history">
          <div class="name-h" v-if="!showClaimTicketsBtn">{{ $t("menu.notWinningPrize") }}</div>
          <div class="name-h" v-if="showClaimTicketsBtn">
            <span v-if="(currentViewUserInfo.bracket+1)==1">{{ $t("menu.hitTheTop1") }}</span>
            <span v-if="(currentViewUserInfo.bracket+1)==2">{{ $t("menu.hitTheTop2") }}</span>
            <span v-if="(currentViewUserInfo.bracket+1)==3">{{ $t("menu.hitTheTop3") }}</span>
            <span v-if="(currentViewUserInfo.bracket+1)==4">{{ $t("menu.hitTheTop4") }}</span>
            <span v-if="(currentViewUserInfo.bracket+1)==5">{{ $t("menu.hitTheTop5") }}</span>
            <span v-if="(currentViewUserInfo.bracket+1)==6">{{ $t("menu.hitTheTop6") }}</span>
          </div>
          <div class="prev-img-p">
            <span @click="getPgage2(0)"><img src="../../../../../../public/images/lukyStar/icon-index.png" alt="" /></span>
            <span @click="getPgage2(1)"><img src="../../../../../../public/images/lukyStar/icon-left.png" alt="" /></span>
            <span @click="getPgage2(2)"><img src="../../../../../../public/images/lukyStar/icon-right.png" alt="" /></span>
            <span @click="getPgage2(3)"><img src="../../../../../../public/images/lukyStar/icon-wei.png" alt="" /></span>
          </div>
        </div>
        <div class="star-h" v-if="showClaimTicketsBtn">
          <i>{{viewLottery.amountCollectedInCake}}</i><span>{{ currentCurrencyText }}</span>
        </div>
        <div class="p-total-history" v-if="showClaimTicketsBtn">${{conversionToUsdt(viewLottery.amountCollectedInCake)}}</div>
        <div class="p-total-history" v-if="showClaimTicketsBtn">
          <i>{{0>currentViewUserInfo.bracket?0:amountConversion(viewLottery.cakePerBracket[currentViewUserInfo.bracket])}}</i><span>{{ currentCurrencyText }}</span><span>{{ $t("menu.each") }}</span>
        </div>
        <div class="p-total-history" v-if="showClaimTicketsBtn">
          <i>{{0>currentViewUserInfo.bracket?0:viewLottery.countWinnersPerBracket[currentViewUserInfo.bracket]}}</i><span>{{ $t("menu.winningLuckyStar") }}</span>
        </div>
        <!-- 中奖介绍内容 end -->
        <!-- 中奖号码 begin -->
        <div class="prize-box mt20">
          <div class="num all-com-winning openPrizeNumber">
            <span v-for="(item, index) in winningNumbersList" :key="index">
              <img v-if="item == '0'" src="../../../../../../public/images/lukyStar/num0.png" alt="" />
              <img v-if="item == '1'" src="../../../../../../public/images/lukyStar/num1.png" alt="" />
              <img v-if="item == '2'" src="../../../../../../public/images/lukyStar/num2.png" alt="" />
              <img v-if="item == '3'" src="../../../../../../public/images/lukyStar/num3.png" alt="" />
              <img v-if="item == '4'" src="../../../../../../public/images/lukyStar/num4.png" alt="" />
              <img v-if="item == '5'" src="../../../../../../public/images/lukyStar/num5.png" alt="" />
              <img v-if="item == '6'" src="../../../../../../public/images/lukyStar/num6.png" alt="" />
              <img v-if="item == '7'" src="../../../../../../public/images/lukyStar/num7.png" alt="" />
              <img v-if="item == '8'" src="../../../../../../public/images/lukyStar/num8.png" alt="" />
              <img v-if="item == '9'" src="../../../../../../public/images/lukyStar/num9.png" alt="" />
            </span>
          </div>
          <!-- 自己的号码 begin -->
          <div class="num all-com-winning my-history-number">
            <!-- 每期中奖号码 begin -->
            <div v-for="(item, index) in myNumberList" :key="index" :class="[item == newNumber[index] ? 'isPrize' : '']">
              <i>{{ item }}</i>
            </div>
            <!-- 每期中奖号码 end -->
          </div>
          <!-- 自己的号码 end -->
          <!-- 比对中奖号码 begin -->
          <div class="num all-com-winning is-check-number">
            <span v-for="(item, index) in myNumberList" :key="index">
              <img v-if="item !== newNumber[index]" src="../../../../../../public/images/lukyStar/icon-cuowu.png" alt="" />
              <img v-if="item == newNumber[index]" src="../../../../../../public/images/lukyStar/icon-zhengque.png" alt="" />
            </span>
          </div>
          <!-- 比对中奖号码 end -->
        </div>
        
        <!-- 中奖号码 end -->
      </div>
      <div class="history-lq-box" v-if="showClaimTicketsBtn">
        <div class="title-g">{{ $t("menu.roundTotalRewards") }}</div> 
        <div class="flex-item">
          <div class="item">
            <div class="num">{{totalReward}}</div>
            <div class="type">{{currentCurrencyText}}</div>
          </div>
          <!-- <div class="item">
            <div class="num">827,394</div>
            <div class="type">ZEED</div>
          </div>
          <div class="item">
            <div class="num">827,394</div>
            <div class="type">YEED</div>
          </div> -->
        </div> 
        <div><button class="no-data-btn" @click="claimTickets()" :disabled="claimTicketsBtn" :class="{disabled_btn:claimTicketsBtn}">{{ $t("menu.receiveRewards") }}</button></div> 
      </div>
    </div>
    <!-- 有申购数据 end -->
    <Star-Wallet v-if="dialogShow" :id="0" @transfer="openDialog"></Star-Wallet>
    <Exchange-Dialog v-if="isShowDialog" :isType="isTypeChoosed" :currencyType="currentCurrency" @transfer="handleTypeData"></Exchange-Dialog>
  </div>
</template>
<script>
// import '../../styles/CompletedRounds.css';
import "../../styles/common.css";
import StarWallet from "../../common/StarWallet.vue";
import ExchangeDialog from "../../Dialog/ExchangeDialog.vue";
// import { threadId } from "worker_threads";
const mLottery = require("../../../../../utils/mLottery");

export default {
  components: { StarWallet, ExchangeDialog },
  data() {
    return {
      connectedStatus: 0, //未连接=0;连接成功=1
      dialogShow: false,
      isRoundsData: true, //是否有申购数据
      prizeNum: "732", //第n次开奖
      currentPage: 0, //当前页
      totalPage: 0, //总页数
      currentInfoPage: 0, //详情-当前页
      infoTotalPage: 0, //详情-总页数
      winningNumbersList: [], //中奖号码
      myNumberList: [], //自己的号码
      currentCurrency: 0, //0是STAR 1是ZEED 2是YEED
      isTypeChoosed: "0", //0选择币种  1点击申购
      isShowDialog: false, //是否打开选择币种弹窗
      newNumber: {},

      lotteryId: 0,
      currencyList: ["STAR", "ZEED", "YEED"],
      viewLottery: {
        status: "0", // Pending没开始 0，Open开始 1，Close停止投注 2，Claimable可以领奖 3
        startTime: "0", //开始时间
        endTime: "0", // 结束投注时间
        priceTicketInCake: "0", // 一张彩票的价格，对应star、zeed、yeed
        discountDivisor: "0", // 这个变量不用理
        rewardsBreakdown: [], //每个挡位的奖励比例， x/10000
        treasuryFee: "0", // 打入黑洞的比例，x/10000
        cakePerBracket: [], // 开奖后，每个挡位中奖币数量
        countWinnersPerBracket: [], // 开奖后，每个挡位中奖的用户数
        firstTicketId: "0",
        firstTicketIdNextLottery: "0",
        amountCollectedInCakePre: "0", // 上轮剩下来的币
        amountCollectedInCake: "0", // 本轮奖池的币
        finalNumber: "0", // 开奖号码
        rewardsBreakdownAmount: [],
        treasuryAmount: 0,
      },
      priceList: [0, 0, 0],
      finalNumberList: [], //开奖号码
      formatTimeToType1: mLottery.default.formatTimeToType1,
      amountConversion: mLottery.default.amountConversion,
      // statusText: ["没开始", "开始", "停止投注", "可以领奖"],

      viewUserInfoList: [],//
      currentViewUserInfo: {
        ticketId: 0,
        ticketNumber: '',// 彩票号码
        status: '', //false是是否已经领奖
        bracket: '',
      },//当前用户彩票信息
      claimTicketsDataList: {
        lotteryId: 0,
        ticketIds: [],
        brackets: []
      },//领取奖励数据
      showClaimTicketsBtn:false,//是否展示领取奖励按钮
      claimTicketsBtn:false,
      totalReward:0,// 共计奖励
    };
  },
  created: function () {
    var address = localStorage.getItem("address");
    if (address) {
      this.connectedStatus = 1;
    }
    this.compareNumber();

    this.currentCurrencyText = this.currencyList[this.currentCurrency];

    //
    mLottery.default.switchContract(this.currentCurrency);
    mLottery.default.viewCurrentLotteryId().then((currentLotteryId) => {
      console.log("currentLotteryId", currentLotteryId);
      // 获取最大回合
      mLottery.default.viewLottery(currentLotteryId).then(viewLotteryRes => {
        let lotteryId = currentLotteryId
        if (viewLotteryRes.status < 3) {
          lotteryId = currentLotteryId - 1
        }

        this.lotteryId = lotteryId;
        this.currentPage = lotteryId;
        this.totalPage = lotteryId;

        this.getViewData(); //获取页面数据
      })

    });

    // 获取Star价格
    mLottery.default.getStarAddrPrice().then((starPrice) => {
      this.priceList[0] = starPrice;
    });
    // 获取Zeed价格
    mLottery.default.getZeedAddrrPrice().then((zeedPrice) => {
      this.priceList[1] = zeedPrice;
    });
    // 获取Yeed价格
    mLottery.default.getYeedAddrPrice().then((yeedPrice) => {
      this.priceList[2] = yeedPrice;
    });
  },
  methods: {
    compareNumber() {
      let _index = 0;
      let obj = {};
      this.winningNumbersList.map((item, index) => {
        if (index === 0) {
          if (item === this.myNumberList[index]) {
            obj[index] = item;
            _index++;
          }
        }
        if (item === this.myNumberList[index] && _index === index) {
          obj[index] = item;
          _index++;
        }
      });
      // console.log(obj);
      this.newNumber = obj;
    },
    chooseCurrency() {
      this.isShowDialog = true;
      this.isTypeChoosed = "0";
    },
    getPgage(type) {
      //type 0是首页 1是上一页 2是下一页 3是尾页
      console.log(type);
      if (type == 0) {
        this.currentPage = 1;
        this.getViewData() //获取页面数据
      }
      if (type == 1 && this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
        this.getViewData() //获取页面数据
      }
      if (type == 2 && this.currentPage < this.totalPage) {
        this.currentPage = this.currentPage + 1;
        this.getViewData() //获取页面数据
      }
      if (type == 3) {
        this.currentPage = this.totalPage;
        this.getViewData() //获取页面数据

      }
    },
    getPgage2(type) {
      console.log(type); //type 0是首页 1是上一页 2是下一页 3是尾页
      if (type == 0 && this.currentInfoPage > 0) {
        this.currentInfoPage = 1;
        this.changeInfoData()//改变详情数据
      }
      if (type == 1 && this.currentInfoPage > 1) {
        this.currentInfoPage = this.currentInfoPage - 1;
        this.changeInfoData()//改变详情数据
      }
      if (type == 2 && this.currentInfoPage < this.infoTotalPage) {
        this.currentInfoPage = this.currentInfoPage + 1;
        this.changeInfoData()//改变详情数据
      }
      if (type == 3 && this.currentInfoPage < this.infoTotalPage) {
        this.currentInfoPage = this.infoTotalPage;
        this.changeInfoData()//改变详情数据
      }
    },
    openDialog(connectStatus, type) {
      if (type == 1) {
        //type=1 打开钱包弹窗，type=2 关闭钱包弹窗
        this.dialogShow = true;
        console.log(connectStatus, "==", type);
      }
      if (type == 2) {
        this.dialogShow = false;
        this.connectedStatus = connectStatus;
      }
    },
    handleTypeData(type, isClose, currType) {
      if (type == "0") {
        //此判断里进行操作-选择币种底部那个弹窗
        this.currentCurrency = currType; //选择后赋值

        this.currentCurrencyText = this.currencyList[this.currentCurrency]
        mLottery.default.switchContract(this.currentCurrency)
        this.getViewData() //获取页面数据
      }
      if (isClose == 0) {
        //此判断里进行关闭弹窗的操作
        this.isShowDialog = false;
      } else {
        this.isShowDialog = true;
      }
    },
    // 获取页面数据
    getViewData() {
      let that = this;
      let lotteryId = that.currentPage;
      that.lotteryId = lotteryId;
      // Step1 根据lotteryId获得整一局的信息
      mLottery.default.viewLottery(lotteryId).then((viewLotteryRes) => {
        let viewLottery = Object.assign({}, viewLotteryRes);
        viewLottery.amountCollectedInCake = mLottery.default.amountConversion(
          viewLottery.amountCollectedInCake
        );
        let rewardsBreakdownAmount = [];
        for (let index = 0; index < viewLottery.rewardsBreakdown.length; index++) {
          rewardsBreakdownAmount[index] =
            Math.floor(
              ((viewLottery.amountCollectedInCake *
                viewLottery.rewardsBreakdown[index]) /
                10000) *
              1000000
            ) / 1000000;
        }
        viewLottery.rewardsBreakdownAmount = rewardsBreakdownAmount;
        viewLottery.treasuryAmount =
          Math.floor(
            ((viewLottery.amountCollectedInCake * viewLottery.treasuryFee) /
              10000) *
            1000000
          ) / 1000000;

        that.viewLottery = Object.assign({}, that.viewLottery, viewLottery);
        console.log(that.viewLottery);

        // 开奖号码 处理

        that.finalNumberList = mLottery.default.formatTicketNumberForResponse(viewLottery.finalNumber); // 格式化彩票号码
        that.winningNumbersList = that.finalNumberList
        console.log(
          "finalNumber",
          viewLotteryRes.finalNumber,
          that.finalNumberList
        )

        // Step2 查看用户已购买彩票
        mLottery.default.viewUserInfoForLotteryId(lotteryId).then(viewUserInfoRes => {
          let viewUserInfo = Object.assign({}, viewUserInfoRes)
          let count = viewUserInfo[3]
          console.log('viewUserInfoRes count', count)
          let viewUserInfoList = []
          // 领取奖励数据记录
          that.claimTicketsDataList.lotteryId = lotteryId
          let _ticketIds=[]
          let _brackets =[]
          let _totalReward = 0
          for (let i = 0; i < count; i++) {
            let ticketNumber = mLottery.default.formatTicketNumberForResponse(viewUserInfo[1][i]) // 格式化彩票号码
            let bracket = mLottery.default.getTicketNumberBracket(this.finalNumberList, ticketNumber) //需要在前端判断中奖了多少位，0~5， 中奖一位传0，中奖6位传5
            // 判断中奖位数
            let ticketId = viewUserInfo[0][i]
            let status = viewUserInfo[2][i]
            viewUserInfoList.push({
              lotteryId: lotteryId,
              ticketId: ticketId,
              ticketNumber: ticketNumber,// 彩票号码
              status: status,
              bracket: bracket,
            })
            // 领取奖励数据记录
            if(bracket>=0 && status==false){
              _ticketIds.push(ticketId)
              _brackets.push(bracket)

              // 汇总奖励
              _totalReward += mLottery.default.amountConversion(that.viewLottery.cakePerBracket[bracket])
            }
            
          }
          that.showClaimTicketsBtn = _ticketIds.length>0 //是否展示领取奖励按钮
          that.claimTicketsDataList.ticketIds = _ticketIds
          that.claimTicketsDataList.brackets = _brackets

          that.totalReward = _totalReward

          that.viewUserInfoList = viewUserInfoList
          if (count > 0) {
            that.currentInfoPage = 1
            that.infoTotalPage = count
          } else {
            that.currentInfoPage = 0
            that.infoTotalPage = 0
          }
          that.changeInfoData()//改变详情数据

          console.log(that.viewUserInfoList)

        })
      })

    },
    // 转换位usdt
    conversionToUsdt(_amount) {
      return (
        Math.floor(_amount * this.priceList[this.currentCurrency] * 1000000) /
        1000000
      );
    },
    // 改变详情数据
    changeInfoData() {

      if (this.currentInfoPage > 0 || this.currentInfoPage <= this.infoTotalPage) {
        let index = this.currentInfoPage - 1
        let viewUserInfo = this.viewUserInfoList[index]
        this.myNumberList = viewUserInfo.ticketNumber
        this.compareNumber();

        this.currentViewUserInfo = viewUserInfo
        this.viewRewardsForTicketId()//查看中奖信息

      } else {
        this.myNumberList = []
      }
    },
    //查看中奖信息
    viewRewardsForTicketId() {
      let _lotteryId = this.lotteryId
      let _ticketId = this.currentViewUserInfo.ticketId
      let _bracket = this.currentViewUserInfo.bracket
      console.log('viewRewardsForTicketId', _lotteryId, _ticketId, _bracket)
      // if (_bracket >= 0) {
        _bracket = 1
        mLottery.default.viewRewardsForTicketId(_lotteryId, _ticketId, _bracket).then(res => {
          console.log(res)
        })
      // }

    },
    //领取奖励
    claimTickets() {
      let _lotteryId = this.claimTicketsDataList.lotteryId
      let _ticketIds = this.claimTicketsDataList.ticketIds
      let _brackets = this.claimTicketsDataList.brackets
      // console.log('领取奖励 param', _lotteryId, _ticketIds, _brackets)
      if (_ticketIds.length > 0) {
        this.claimTicketsBtn = true
        mLottery.default.claimTickets(_lotteryId, _ticketIds, _brackets).then(res => {
          console.log('领取奖励 res', res)
          this.claimTicketsBtn = false
        })
      }else{
        this.$toast(this.$t('menu.noWinningData'));
      }

    }

  },
};
</script>

<style>
</style>